<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">ADAS数据采集及回灌系统开发</h1>
        <p data-caption-delay="100" class="wow fadeInRight">为自动驾驶相关控制器开发、算法模型训练、控制器测试验证及HIL或LabCAR测试提供数据支持</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="solution-intros wow fadeInLeft adas-solution-intros">
      <div class="container">
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <div class="intro-text">
                <p>ADAS域数据采集与回灌系统是以Vector公司VP系列产品为基础，结合客户对采集回灌业务的实际需要，定制化设计的产品应用解决方案。</p>
              </div>
              <div class="intro-text">
                <p>方案可实现MIL、HIL、DIL台架及车内激光雷达、摄像头、多路CAN/CANFD及以太网等数据的采集、处理、存储、分析与回灌，为自动驾驶相关控制器开发、自动驾驶的算法模型训练、控制器测试验证及HIL或LabCAR测试提供数据支持。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="intro-picture">
              <img src="../../../assets/images/adas-solution-introduction-image.png" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="vehicle-solution-sections wow fadeInRight">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <div class="all-function">
          <el-row :gutter="10">
            <el-col :span="24">
              <div style="margin-top: 30px;font-size: 14px;color: #414141;">
                <h3>数据采集</h3>
                <ul class="function-list">
                  <li>支持数据路由、Bypass及TAP功能，可执行实车旁路数据采集及数据路由；支持激光雷达、毫米波雷达、摄像头数据采集</li>
                  <li>支持多路CAN/CANFD数据采集</li>
                  <li>支持多路通道以上的GMSL2/FPD-Link的图像采集</li>
                  <li>支持多路100/1000Base-Tx/T数据采集</li>
                  <li>支持车内以太网100/1000Base-T1数据采集</li>
                  <li>提供PPS/TTL同步信号，提供PTP/gPTP同步</li>
                </ul>
                <el-divider></el-divider>
                <h3>数据管理</h3>
                <ul class="function-list">
                  <li>构建可执行、可监管的数据标准体系</li>
                  <li>数据处理：支持数据清洗、数据校验、数据转换、数据融合，支持对数据进行再加工</li>
                  <li>数据存储：支持构建不同类型业务主题库，支持音频、图像、视频等大数据存储，提供面向海量数据的存储计算能力</li>
                  <li>数据分析：基于AI技术对采集数据开展数据建模及数据训练，提供数据可视化管理分析界面，支持数据报表、即席查询、指标分析、仪表盘等形态，为数据挖掘与分析预测提供基础</li>
                </ul>
                <el-divider></el-divider>
                <h3>数据回灌</h3>
                <ul class="function-list">
                  <li>支持多种实车数据（激光雷达、毫米波雷达、GMSL/FPD-LINK、CAN/LIN/Ethernet等）回灌能力，具备在MIL、DIL、VIL中集成</li>
                  <li>支持虚拟数据回灌能能力（VTD场景数据，虚拟CAN/CANFD及以太网数据等）</li>
                  <li>支持数据后处理及功能二次开发</li>
                  <li>回灌图像数据可执行YUV422、raw16格式无压回灌</li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections wow fadeInLeft">
      <div class="container">
        <div class="normal-title">优势亮点</div>
        <div class="all-function">
          <ul class="priority-list">
            <li>采集过程支持同步授时，支持多通道GMSL/FPD-LINK摄像头的图像采集</li>
            <li>基于Vector的硬件和软件实现总线数据采集及回灌，成熟度高</li>
            <li>采集和回灌可采用一套设备，具备一定成本优势</li>
            <li>打造数据采集、加工、分析、回灌闭环流程，形成数据规范，为后续业务分析提供技术支撑</li>
            <li>可基于技术上的二次开发，定制化满足客户业务需求</li>
          </ul>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import blueBg from "@/assets/images/solution-eletric-function-bg.png";
import bzInfo from "@/components/BzInfo.vue";
export default {
  name: "adas",
  components: {
    mainFooter,
    mainNav,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-7',
      blueBg: blueBg,
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    document.title = "ADAS数据采集及回灌系统开发 - 解决方案 - 东信创智";
    new this.$wow.WOW().init()
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>
